import React, { useEffect, useReducer, useRef } from "react";
import "./css/sharefeature.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { BiMailSend } from "react-icons/bi";
import { useForm } from "react-hook-form";
import {
  sendBusinessTemplateToEmail,
  sendResourcesEmail,
} from "../../store/api";
import loader from "../../assets/loader.gif";
import "react-toastify/dist/ReactToastify.css";
import EmailEditor from "./EmailEditor";

function ShareFeature({
  dependencies: {
    State,
    Dispatch,
    isLeadSent,
    data,
    uid,
    email_template,
  },
}) {
  const editorRef = useRef(null);
  if (editorRef.current) {
    console.log(editorRef.current.getContent());
  }
  const status = {
    loader: false,
  };

  const defaultFields = [
    {
      label: "TO",
      fieldFor: "email",
      required: true,
      placeholder: "Please enter the email to send",
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      tag: "input",
    },
    {
      label: "SUBJECT",
      fieldFor: "subject",
      required: true,
      placeholder: "Enter an subject",
      pattern: /^[a-zA-Z ]+$/,
      type: "text",
      tag: "input",
    },
  ];

  const shareReducer = (state, action) => {
    switch (action.type) {
      case "API_LOADER": {
        return {
          ...state,
          loader: action.state,
        };
      }
      default:
        return state;
    }
  };
  const [shareState, shareDispatch] = useReducer(shareReducer, status);
  const values = {
    email: "",
    subject: email_template.subject,
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
      subject: "",
    },
    values,
  });
  async function sendEmail(dataEmail) {
    shareDispatch({ type: "API_LOADER", state: true });
    let payload = new FormData();
    payload.append("uid", uid);
    payload.append("email_address", dataEmail?.email);
    payload.append("subject", dataEmail?.subject);
    payload.append("email_instance_id", data?.email_instance_id);
    payload.append("source_id", data?.source_id);
    payload.append(
      "body",
      editorRef.current ? editorRef.current.getContent() : "",
    );

    await sendResourcesEmail(payload)
      .then((res) => {
        if (res?.status === "success") {
          toast.success(res?.message);
        } else if (res?.status === "failed") {
          toast.error(res?.message);
        }
        if (isLeadSent === false) {
          setTimeout(() => {
            Dispatch({
              type: "CHANGE_BOTH_POPUP_STATE",
              name1: "isSharePanelOpen",
              value1: !State?.isSharePanelOpen,
              name2: "isLeadPanelOpen",
              value2: !State?.isLeadPanelOpen,
            });
          }, 1500);
        } else {
          setTimeout(() => {
            Dispatch({
              type: "CHANGE_ONE_POPUP_STATE",
              name: "isSharePanelOpen",
              value: !State?.isSharePanelOpen,
            });
          }, 1500);
        }
        shareDispatch({ type: "API_LOADER", state: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    reset({
      email: "",
      subject: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <>
      <div className="share-pop-overall">
        <div className="share-pop-content">
          <div className="share-pop-top-plane">
            <div className="close-icon-plane">
              <AiFillCloseCircle
                style={{
                  margin: "5px",
                  color: "#5412e0",
                  cursor: "pointer",
                  width: "2rem",
                  height: "2rem",
                }}
                onClick={(e) => {
                  Dispatch({
                    type: "CHANGE_ONE_POPUP_STATE",
                    name: "isSharePanelOpen",
                    value: !State?.isSharePanelOpen,
                  });
                }}
              />
            </div>
            <div className="title-plane">
              <h3 className="title" style={{ color: "black" }}>
                compose
              </h3>

              <h4 className="secondary-title">Share content, quick and easy</h4>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(sendEmail)}
            className="share-pop-main-plane"
          >
            {defaultFields?.map((field, index) => {
              return (
                <>
                  {field?.tag === "input" ? (
                    <div className="input-field" key={index}>
                      <div className="input-block">
                        <label htmlFor={field?.fieldFor} id="input-label">
                          {field?.label.toUpperCase()}
                        </label>
                        <input
                          placeholder={field?.placeholder}
                          {...register(field?.fieldFor, {
                            required: field?.required,
                            pattern: field?.pattern,
                          })}
                          type={field?.type}
                          className={
                            errors?.[field?.fieldFor]
                              ? "input-box-err"
                              : "input-box"
                          }
                          id={field?.fieldFor}
                        />
                      </div>
                      <div className="err-block">
                        {errors?.[field?.fieldFor] &&
                          (errors?.[field?.fieldFor].type === "required" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              This field is required !!
                            </span>
                          ) : errors?.[field?.fieldFor].type === "pattern" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              Invalid input !!
                            </span>
                          ) : null)}
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
            <EmailEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={email_template?.body}
              init={{
                height: 500,
                menubar: "format",
                convert_urls: false,
                plugins: [
                  "advlist",
                  "anchor",
                  "autolink",
                  "help",
                  "image",
                  "link",
                  "lists",
                  "searchreplace",
                  "table",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <button
              type="submit"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
                cursor: "pointer",
                fontFamily: " Nunito Sans, sans-serif",
                // backgroundColor:
                //   State?.html?.accent_primary_color !== null &&
                //   typeof State?.html?.accent_primary_color !== 'undefined'
                //     ? State?.html?.accent_primary_color
                //     : '#5412e0',
                border: "1px solid gray",
                borderRadius: "0.3rem",
              }}
            >
              <p
                style={{
                  // color:
                  //   State?.html?.accent_secondary_color !== null &&
                  //   typeof State?.html?.accent_secondary_color !== 'undefined'
                  //     ? State?.html?.accent_secondary_color
                  //     : '#ffff',
                  margin: 0,
                  fontSize: "0.9rem",
                  marginRight: 6,
                  fontWeight: "800",
                }}
              >
                SEND
              </p>
              {shareState?.loader === true ? (
                <img src={loader} width="20px" height="20px"></img>
              ) : null}
            </button>
          </form>
          <hr></hr>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default ShareFeature;
