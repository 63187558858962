import React from "react";
import { BiFontFamily } from "react-icons/bi";
import Veespace from "../../assets/veespacesPowered2023.png";

export default function Footer({ logoEnabled, profileURL, accentColor }) {
  return (
    <>
      {logoEnabled === "Yes" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: "auto",
            position: "sticky",
            bottom: "0px",
            background: "transparent",

            // backgroundColor: "#f2e6ff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                accentColor.accentSecondary !== null &&
                typeof accentColor.accentSecondary !== "undefined" &&
                accentColor.accentSecondary !== ""
                  ? accentColor.accentSecondary
                  : "rgb(84, 18, 224)",
              opacity: "0.9",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              width: "auto",
              height: "auto",
              padding: "5px 27px",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            onClick={() => {
              window.open(
                `https://www.veespaces.com/digital-business-cards/?utm_source=poweredbylogo&utm_medium=digitalcardprofile&utm_content=${profileURL}`,
                "_blank",
              );
            }}
          >
            <p
              style={{
                width: "auto",
                height: "auto",
                background: "transparent",

                color:
                  accentColor.accentPrimary !== null &&
                  typeof accentColor.accentPrimary !== "undefined" &&
                  accentColor.accentPrimary !== ""
                    ? accentColor.accentPrimary
                    : "#ffff",
                fontWeight: 600,
                fontSize: "0.7rem",
                margin: 0,
              }}
            >
              Create Digital Cards using VeeSpaces
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}
