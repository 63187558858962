import React, { useReducer } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa";
import { MdAttachEmail, MdEmail } from "react-icons/md";
import "./css/staffpopup.scss";
import { DownloadVCard } from "./downloadVCard";

export default function StaffPopup({ dependencies: { State, Dispatch } }) {
  return (
    <>
      <div
        className={`bsp-pop-ovrll${
          State.staffPopupState ? "-active" : "-inactive"
        }`}
      >
        <div
          className={`bsp-pop-wrap${
            State.staffPopupState ? "-active" : "-inactive"
          }`}
          style={{
            backgroundColor:
              typeof State?.html?.accent_secondary_color !== "undefined" &&
              State?.html?.accent_secondary_color !== null &&
              State?.html?.accent_secondary_color !== ""
                ? State?.html?.accent_secondary_color
                : "#fff",
          }}
        >
          <div className="bsp-pop-cont">
            <div className="bsp-pop-cont-close">
              <AiFillCloseCircle
                color={
                  State?.html?.accent_primary_color !== null &&
                  typeof State?.html?.accent_primary_color !== "undefined"
                    ? State?.html?.accent_primary_color
                    : "#5412e0"
                }
                size={30}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Dispatch({ type: "STAFF_POPUP_STATE" });
                  Dispatch({ type: "SELECTED_STAFF_DATA", data: [] });
                }}
              />
            </div>
            {State.selectedStaffData.map((staff, index) => {
              return (
                <>
                  <div className="bsp-pop-cont-img-wrap" key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100px",
                        borderRadius: "100%",
                        border: `2px solid ${
                          State?.html?.accent_primary_color !== null &&
                          typeof State?.html?.accent_primary_color !==
                            "undefined"
                            ? State?.html?.accent_primary_color
                            : "#5412e0"
                        }`,
                        padding: "4px",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "100%",
                          border: "1px solid gray",
                          width: "100px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          height: "100px",
                          backgroundImage: `url(${
                            staff.exhibitor_staff_staffs_profile_image !==
                              (process.env.REACT_APP_MODE === "production"
                                ? "https://app.veespaces.com/platform/resources/data/user_data/staff_profile_images/"
                                : "https://dev-app.veespaces.com/platform/resources/data/user_data/staff_profile_images/") &&
                            staff.exhibitor_staff_staffs_profile_image !== null
                              ? encodeURI(
                                  staff.exhibitor_staff_staffs_profile_image,
                                )
                              : "https://media.istockphoto.com/id/1209654046/vector/user-avatar-profile-icon-black-vector-illustration.jpg?s=612x612&w=0&k=20&c=EOYXACjtZmZQ5IsZ0UUp1iNmZ9q2xl1BD1VvN6tZ2UI="
                          })`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="bsp-pop-cont-main-wrap" key={index}>
                    <div className="bsp-pop-cont-main-name">
                      <span
                        style={{
                          lineHeight: "15px",
                          fontSize: "1.2rem",
                          color:
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "rgb(48, 48, 48)",
                          fontWeight: 600,
                        }}
                      >
                        {staff?.exhibitor_staff_first_name}
                      </span>
                      <span
                        style={{
                          lineHeight: "15px",
                          fontSize: "1.2rem",
                          color:
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "rgb(48, 48, 48)",
                          fontWeight: 600,
                        }}
                      >
                        {staff?.exhibitor_staff_last_name}
                      </span>
                    </div>
                    <div className="bsp-pop-cont-main-desig">
                      <span
                        style={{
                          lineHeight: "15px",
                          color:
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "gray",
                          fontWeight: 500,
                          fontStyle: "italic",
                          fontSize: "0.89rem",
                        }}
                      >
                        {staff?.exhibitor_staff_job_title}
                        {staff?.exhibitor_staff_company_name !== "" &&
                        typeof staff?.exhibitor_staff_company_name !==
                          "undefined"
                          ? " - " + staff?.exhibitor_staff_company_name
                          : null}
                      </span>
                    </div>
                    {staff?.exhibitor_staff_email_addresss !== "" ? (
                      <div className="bsp-pop-cont-main-email">
                        <MdAttachEmail
                          color={
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "gray"
                          }
                          size={15}
                        />
                        <span
                          style={{
                            lineHeight: "15px",
                            fontSize: "0.8rem",
                            color:
                              State?.html?.accent_primary_color !== null &&
                              typeof State?.html?.accent_primary_color !==
                                "undefined" &&
                              State?.html?.accent_primary_color !== ""
                                ? State?.html?.accent_primary_color
                                : "gray",
                            fontWeight: 300,
                          }}
                        >
                          {staff?.exhibitor_staff_email_addresss}
                        </span>
                      </div>
                    ) : null}
                    {staff?.exhibitor_staff_address1 !== "" ? (
                      <div className="bsp-pop-cont-main-address">
                        <FaMapMarkedAlt
                          color={
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "gray"
                          }
                          size={15}
                        />
                        <span
                          style={{
                            lineHeight: "15px",
                            fontSize: "0.8rem",
                            color:
                              State?.html?.accent_primary_color !== null &&
                              typeof State?.html?.accent_primary_color !==
                                "undefined" &&
                              State?.html?.accent_primary_color !== ""
                                ? State?.html?.accent_primary_color
                                : "gray",
                            fontWeight: 300,
                          }}
                        >
                          {staff?.exhibitor_staff_address1}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  {staff?.exhibitor_staff_mobile_no !== "" ? (
                    <div className="bsp-pop-cont-field-wrap" key={index}>
                      <FaPhoneAlt
                        color={
                          State?.html?.accent_primary_color !== null &&
                          typeof State?.html?.accent_primary_color !==
                            "undefined" &&
                          State?.html?.accent_primary_color !== ""
                            ? State?.html?.accent_primary_color
                            : "gray"
                        }
                        size={20}
                      />
                      <span
                        style={{
                          display: "flex",
                          color:
                            State?.html?.accent_primary_color !== null &&
                            typeof State?.html?.accent_primary_color !==
                              "undefined" &&
                            State?.html?.accent_primary_color !== ""
                              ? State?.html?.accent_primary_color
                              : "gray",
                          fontWeight: 600,
                          marginLeft: "5px",
                        }}
                      >
                        {staff?.exhibitor_staff_mobile_no_country_code +
                          " " +
                          staff?.exhibitor_staff_mobile_no}
                      </span>
                    </div>
                  ) : null}
                </>
              );
            })}
            <div className="bsp-pop-cont-field-but">
              <div
                style={{
                  backgroundColor:
                    State?.html?.accent_primary_color !== null &&
                    typeof State?.html?.accent_primary_color !== "undefined"
                      ? State?.html?.accent_primary_color
                      : "#5412e0",
                  color:
                    State?.html?.accent_secondary_color !== null &&
                    typeof State?.html?.accent_secondary_color !== "undefined"
                      ? State?.html?.accent_secondary_color
                      : "#ffff",
                }}
                className="bsp-pop-cont-field-but-in"
                onClick={() => {
                  DownloadVCard(State.selectedStaffData);
                }}
              >
                <span>Download</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
