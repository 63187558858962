import "./App.css";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const matomo = document.createElement("script");
    matomo.type = "text/javascript";
    matomo.src = "https://cards.veespaces.com/matomoTracking.js";
    matomo.async = true;

    document.body.appendChild(matomo);
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/cards/"
    ) {
      navigate("/404");
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
