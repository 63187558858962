import axios from "axios";
import { Api_Key } from "../index";
var CryptoJS = require("crypto-js");

const apiRootPath = process.env.REACT_APP_API_ROOT_PATH;
const API_SECRET_TOKEN = process.env.REACT_APP_API_SECRET_TOKEN;

//<------------------------------ CONFIG FUNCTION FOR API CALL -------------------------->
const getConfig = (url, method, contentType, data, sendHeader = true,sendApiKey = false) => {
  if (sendApiKey) {
    return {
      url: url,
      method: method,
      headers: {
        "Content-Type": contentType,
        'x-api-key': API_SECRET_TOKEN
      },
      data: data,
    };
  }else if (sendHeader) {
    return {
      url: url,
      method: method,
      headers: {
        "Content-Type": contentType,
        'x-api-key': API_SECRET_TOKEN
      },
      data: data,
    };
  } else {
    return {
      url: url,
      method: method,
      data: data,
      headers: {
        'x-api-key': API_SECRET_TOKEN
      },
    };
  }
};

//<------------------------------------- ENCRYPT FUNCTION ----------------------------------------->
const Encrypt_payload = async (payload, unique_key) => {
  let encrypt_payload = {
    payload,
  };

  let course_encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(encrypt_payload),
    unique_key
  );
  let encrypt_json = {
    iv: course_encrypted.iv.toString(),
    s: course_encrypted.salt.toString(),
    ct: course_encrypted.ciphertext.toString(CryptoJS.enc.Base64),
  };

  let encrypt_json_string = {
    value: { encrypt_payload_string: JSON.stringify(encrypt_json) },
  };

  return encrypt_json_string;
};
//<---------------------------------- DECRYPT FUNCTION ------------------------------------------------>
const Decrypt_response = async (response, unique_key) => {
  let response_data = "";

  if (
    response?.data?.status.localeCompare("success") == 0 &&
    !response?.message
  ) {
    let salt = response?.data?.salt;
    let iv = response?.data?.iv;
    let iterations = response?.data?.iterations;
    let passphrase = unique_key;

    let key = CryptoJS.PBKDF2(passphrase, salt, {
      hasher: CryptoJS.algo.SHA256,
      keySize: 64 / 8,
      iterations: iterations,
    });
    let decrypted = CryptoJS.AES.decrypt(response?.data?.data, key, {
      iv: CryptoJS.enc.Utf8.parse(iv),
    });
    let decrypt_data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    response_data = { value: { decrypt_data } };
    return response_data;
  }
};



export const getCountryCodes = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;
  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  // console.log("CountryCodes",Encrypted_payload)
  const response = await axios(
    getConfig(
      "https://alpha-api.veespaces.com/v4/" + "get-phone-number-country-code",
      "post",
      "application.json"
    )
  )
    .then(async (response) => {
      Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      // console.log("CountryCodes",Decrypted_response?.decrypt_data)
      return Decrypted_response?.decrypt_data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const contactUs = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;

  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  const response = await axios(
    getConfig(
      apiRootPath + "forms/contact-us",
      "post",
      "application/json",
      JSON.stringify(Encrypted_payload)
    )
  )
    .then(async (response) => {
      Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      // console.log("contactUs",Decrypted_response)
      return Decrypted_response?.decrypt_data;
      // return response?.data
    })
    .catch((error) => {
      //console.log(error);
    });
  return response;
};

export const getLeadsManagement = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;
  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  // console.log(Encrypted_payload)
  let response = await axios(
    getConfig(
      apiRootPath + "contact/add",
      "post",
      "application/json",
      payload,
      false
    )
  )
    .then((response) => {
      // console.log(response?.data?.status);
      return response;
    })
    .catch((error) => {
      // console.log(error);
    });
  return response;
};

export const getEmailTemplate = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + `get-email-template`,
      "post",
      "multipart/form-data",
      payload,
      false
    )
  )
    .then(async (response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const getEventsList = async (payload) => {
  let response = await axios(
    getConfig(
      apiRootPath + "/event_list",
      "post",
      "application/json",
      JSON.stringify(payload),
      false
    )
  )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      // console.log(error);
    });
  return response;
};

export const QRCountSender = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + "qr-scan",
      "post",
      "application/json",
      JSON.stringify(payload),
      true,
      true
    )
  )
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });

  return response?.data;
};

export const addDataToMongo = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + "wa-tracking",
      "post",
      "application/json",
      JSON.stringify(payload),
      true,
      true
    )
  )
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });

  return response?.data;
};

export const digitalCardTemplate = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + "digitalcards/get-digital-card-by-id",
      "post",
      "application/json",
      JSON.stringify(payload)
    )
  )
    .then(async (response) => {
      return response?.data?.results[0];
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export const sendBusinessTemplateToEmail = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + "send_mail_with_digital_card",
      "post",
      "application/json",
      JSON.stringify(payload)
    )
  )
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export const sendResourcesEmail = async (payload) => {
  let response = await axios(
    getConfig(
      apiRootPath + "send-email-preview",
      "post",
      "multipart/form-data",
      payload
    )
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {});

  return response;
};
