import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  digitalCardTemplate,
  getCountryCodes,
  getEmailTemplate,
  QRCountSender,
} from "../../store/api";
import "../pages/businesscarddetail.scss";
import "../pages/business.scss";
import { FaShareSquare } from "react-icons/fa";
import { MdContactPhone, MdContentCopy } from "react-icons/md";
import Footer from "../features/Footer";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { addDataToMongo } from "../../store/api";
import StaffPopup from "../features/StaffPopup";
import ShareFeature from "../features/ShareFeature";
import QRCode from "qrcode";
import LeadFeature from "../features/LeadFeature";
import ErrorDynamic from "../errorPages/ErrorDynamic";
import VCard from "vcard-creator";

const BusinessCard = () => {
  let businesscardData = useLoaderData();
  let params = useParams();
  let Details_str = window.navigator.appVersion;
  let Device_info = Details_str.substring(
    Details_str.indexOf("(") + 1,
    Details_str.indexOf(")")
  );
  let Device_info_extract = Device_info.split(";");
  let isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };
  function osDetect() {
    let osversion;
    if (Device_info.toLowerCase().includes("mac")) {
      osversion = "Mac";
    } else if (Device_info.toLowerCase().includes("android")) {
      osversion = "Android";
    } else if (Device_info.toLowerCase().includes("ios")) {
      osversion = "ios";
    } else if (!isMobile.any()) {
      osversion = Device_info_extract[0];
    }
    return osversion;
  }

  function browserDetect() {
    let browserversion;
    let Browser_info = Details_str.substring(
      Details_str.lastIndexOf(")") + 1,
      Details_str.length
    ).trim(" ");
    let Browser_info_extract = Browser_info.split(" ");
    if (Device_info.toLowerCase().includes("android")) {
      browserversion = Browser_info_extract[0].split("/")[0];
    } else if (Device_info.toLowerCase().includes("iphone")) {
      if (Browser_info_extract[0].toLowerCase().includes("crios")) {
        browserversion = Browser_info_extract[0].split("/")[0];
      } else {
        browserversion =
          Browser_info_extract[Browser_info_extract.length - 1].split("/")[0];
      }
    } else if (!isMobile.any()) {
      browserversion = "unknown";
    }
    return browserversion;
  }

  function handsetDetect() {
    let handsetversion;
    if (Device_info.toLowerCase().includes("android")) {
      handsetversion = Device_info_extract[Device_info_extract.length - 1];
    } else if (Device_info.toLowerCase().includes("iphone")) {
      handsetversion = "iPhone";
    } else if (!isMobile.any()) {
      handsetversion = "unknown";
    }
    return handsetversion;
  }
  const navigate = useNavigate();
  const [set, useSet] = useState();

  let initialState = {
    html: { ...businesscardData },
    profile_url: params?.userId,
    countryCodes: [],
    staffPopupState: false,
    selectedStaffData: [],
    isSharePanelOpen: false,
    isLeadPanelOpen: false,
    isLeadSent: false,
    email_template: {
      subject: "",
      body: "",
    },
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "STAFF_POPUP_STATE": {
        return {
          ...state,
          staffPopupState: !state.staffPopupState,
        };
      }
      case "CHANGE_ONE_POPUP_STATE": {
        return {
          ...state,
          [action.name]: action.value,
        };
      }
      case "CHANGE_BOTH_POPUP_STATE": {
        return {
          ...state,
          [action.name1]: action.value1,
          [action.name2]: action.value2,
        };
      }
      case "UPDATE_EMAIL_TEMPLATE": {
        return {
          ...state,
          email_template: {
            subject: action.subject,
            body: action.body,
          },
        };
      }
      case "SELECTED_STAFF_DATA": {
        return {
          ...state,
          selectedStaffData: action.data,
        };
      }
      case "UPDATE_COUNTRY_CODES": {
        return {
          ...state,
          countryCodes: action.data,
        };
      }
      case "UPDATE_LEAD_STATUS": {
        return {
          ...state,
          isLeadSent: action.value,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  let details = {
    Location_details: {
      city: "unknown",
      city_district: "unknown",
      country: "unknown",
      country_code: "unknown",
      neighbourhood: "unknown",
      postcode: "unknown",
      state: "unknown",
      state_district: "unknown",
      suburb: "unknown",
    },
    Longitude: "unknown",
    Latitude: "unknown",
    Browser_CodeName: window.navigator.appCodeName,
    Browser_Name: window.navigator.appName,
    Cookies_Enabled: window.navigator.cookieEnabled,
    Browser_Version: window.navigator.appVersion,
    Browser_Language: window.navigator.language,
    Browser_Online: window.navigator.onLine,
    Platform: window.navigator.platform,
    Device_name: isMobile.any() ? "Mobile" : "PC",
    Handset: handsetDetect(),
    OS: osDetect(),
    Browser: browserDetect(),
  };

  const _dummy_fn = (lat, lon) => {
    if (lat !== "" && lon !== "") {
      axios(
        "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
          lat +
          "&lon=" +
          lon,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((response) => {
        details.Location_details.city =
          response.data.address.length != 0 ? response.data.address.city : "";
        details.Location_details.city_district =
          response.data.address.length != 0
            ? response.data.address.city_district
            : "";
        details.Location_details.country =
          response.data.address.length != 0
            ? response.data.address.country
            : "";
        details.Location_details.country_code =
          response.data.address.length != 0
            ? response.data.address.country_code
            : "";
        details.Location_details.neighbourhood =
          response.data.address.length != 0
            ? response.data.address.neighbourhood
            : "";
        details.Location_details.postcode =
          response.data.address.length != 0
            ? response.data.address.postcode
            : "";
        details.Location_details.state =
          response.data.address.length != 0 ? response.data.address.state : "";
        details.Location_details.state_district =
          response.data.address.length != 0
            ? response.data.address.state_district
            : "";
        details.Location_details.suburb =
          response.data.address.length != 0 ? response.data.address.suburb : "";
        details.Latitude = lat;
        details.Longitude = lon;
      });
    }
  };

  const allowposition = (position) => {
    _dummy_fn(position.coords.latitude, position.coords.longitude);
  };

  const blockposition = (err) => {
    _dummy_fn("", "");
  };

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      allowposition,
      blockposition
    );

    getCountryCodes().then((res) => {
      dispatch({ type: "UPDATE_COUNTRY_CODES", data: res });
    });

    QRCountSender({
      // QR code scan code sending API done only once while loading the page initially
      records: JSON.stringify({
        records: [
          {
            value: {
              ...details,
              page_url: window.location.href,
              profile_id: state.html.profile_id,
              profile_url: state.profile_url,
              uid: state.html.uid,
              campaign_id:
                typeof new URLSearchParams(window.location.search).get(
                  "campaign_id"
                ) !== "undefined" &&
                new URLSearchParams(window.location.search).get(
                  "campaign_id"
                ) !== null
                  ? new URLSearchParams(window.location.search).get(
                      "campaign_id"
                    )
                  : "unknown",
              type: "9",
              qr_scan: new URLSearchParams(window.location.search).has(
                "qr_scan"
              )
                ? new URLSearchParams(window.location.search).get("qr_scan")
                : 0,
            },
          },
        ],
      }),
    });
  }, [businesscardData]);

  function analytics(click_event_name) {
    //Analytics data taken from state that has been collected from QRCode.js page  and
    //then added along with other datas and sent to mongoDB.
    let dataAnalytics = {
      source: "digital-card",
      encoded_data: {
        exhibitor_id: state?.html?.exhibitor_id,
        campaign_id:
          typeof new URLSearchParams(window.location.search).get(
            "campaign_id"
          ) !== "undefined" &&
          new URLSearchParams(window.location.search).get("campaign_id") !==
            null
            ? new URLSearchParams(window.location.search).get("campaign_id")
            : "unknown",
        tour_code: "unknown",
        agentdetails: details,
        data_id: state?.html?.profile_id,
        click_event_name: click_event_name,
        type: "9",
        page_url: window.location.href,
      },
    };

    addDataToMongo(dataAnalytics).then((res) => {
      // console.log(res);
    });
  }

  const fetchQR = () => {
    let URL;

    if (process.env.REACT_APP_MODE === "production") {
      QRCode.toDataURL(
        `https://app.veespaces.com/api/v2/digital-businesscard-vcard-downloader?profile_unique_url=${state.profile_url}`,
        { errorCorrectionLevel: "M", scale: 2 },
        function (err, url) {
          URL = url;
        }
      );
    } else if (process.env.REACT_APP_MODE === "alpha") {
      QRCode.toDataURL(
        `https://alpha-app.veespaces.com/v2/digital-businesscard-vcard-downloader?profile_unique_url=${state.profile_url}`,
        { errorCorrectionLevel: "M", scale: 2 },
        function (err, url) {
          URL = url;
        }
      );
    } else if (process.env.REACT_APP_MODE === "uat") {
      QRCode.toDataURL(
        `https://uat-app.veespaces.com/v2/digital-businesscard-vcard-downloader?profile_unique_url=${state.profile_url}`,
        { errorCorrectionLevel: "M", scale: 2 },
        function (err, url) {
          URL = url;
        }
      );
    }
    return URL;
  };

  useEffect(() => {
    let ele = document.getElementsByTagName("a");
    fetchQR();
    setTimeout(() => {
      let name = document.getElementsByClassName(
        `title_${state.html.template_id}`
      );
      window.document.title =
        typeof name[0].innerHTML !== "undefined" && name[0].innerHTML !== null
          ? name[0].innerHTML + " - Digital Card"
          : "Digital Card";
    }, 3000);

    for (let i = 0; i < ele.length; i++) {
      // let tdTag = document.createElement('td');
      // tdTag.colSpan = 10;
      // let tdButton = document.createElement('button');
      // tdButton.style.cssText = `font-family:poppins;background-color:${state?.html?.accent_secondary_color};border-radius:5px;color:${state?.html?.accent_primary_color};font-weight:500;padding:6px 8px;font-size:10px;border:none;outline:none; cursor:pointer`;
      // tdButton.innerHTML = 'Copy';
      // tdButton.onclick = navigator.clipboard.writeText(
      //   ele[i]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]
      //     ?.innerHTML
      // );
      // tdTag.appendChild(tdButton);

      // ele[i]?.children[0]?.children[0]?.children[0].appendChild(tdTag);
      ele[i].addEventListener("click", (evt) => {
        let val = String(ele[i].getAttribute("id"));

        analytics(val);
      });
    }

    document.addEventListener("DOMContentLoaded", () => {
      if (state?.html?.staff_data && state?.html?.staff_data.length > 0) {
        state?.html?.staff_data.forEach((staff, index) => {
          let element = document.getElementById(`staff_${index}`);
          if (element) {
            element.addEventListener("click", () => {
              dispatch({ type: "STAFF_POPUP_STATE" });
              dispatch({
                type: "SELECTED_STAFF_DATA",
                data: [staff],
              });
            });
          }
        });
      }
    });
    
  }, [state.html.template_id]);

  function downloadICal() {
    const profile_data = state.html.card_profile_data;
    const vcalendar = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//hacksw/handcal//NONSGML v1.0//EN
BEGIN:VEVENT
ORGANIZER;CN=${profile_data?.organiser ? profile_data.organiser : ""}:mailto:${profile_data?.email ? profile_data.email : ""}
DTSTART:${profile_data?.from_date ? formatDate(profile_data.from_date) : ""}
DTEND:${profile_data?.to_date ? formatDate(profile_data.to_date) : ""}
SUMMARY:${profile_data?.title ? profile_data.title : ""}
DESCRIPTION:${profile_data?.description ? profile_data.description : ""}
LOCATION:${profile_data?.venue ? profile_data.venue : ""}
END:VEVENT
END:VCALENDAR`;
    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      const seconds = `0${date.getSeconds()}`.slice(-2);
      return `${year}${month}${day}T${hours}${minutes}${seconds}`;
    }

    const vcalendertext = vcalendar.toString();

    const link = document.createElement("a");
    link.href =
      "data:text/calendar;charset=utf-8," + encodeURIComponent(vcalendertext);
    link.download = profile_data.card_name + ".ics";
    link.click();
  }

  function downloadVCard() {
    const profile_data = state.html.card_profile_data;
    const myVCard = new VCard();

    const lastname = profile_data.card_last_name;
    const firstname = profile_data.card_first_name;
    const company = profile_data.card_company_name;
    const jobtitle = profile_data.card_job_title;
    const role = profile_data.card_headline;
    const email = profile_data.card_email;
    const phone = profile_data.card_phone;
    const address = profile_data.card_address;
    const website = profile_data.card_website_url;

    // myVCard
    //   // Add personal data
    //   .addName(lastname, firstname);
    if (lastname && firstname) {
      myVCard.addName(lastname, firstname);
    } else if (lastname) {
      myVCard.addName(lastname);
    } else if (firstname) {
      myVCard.addName(firstname);
    }
    // Add work data if available
    if (company) myVCard.addCompany(company);
    if (jobtitle) myVCard.addJobtitle(jobtitle);
    if (role) myVCard.addRole(role);
    // Add contact data
    if (email) myVCard.addEmail(email);
    if (phone) myVCard.addPhoneNumber(phone, "PREF;WORK");
    if (address) myVCard.addAddress(address);
    if (website) myVCard.addURL(website);

    const vCardText = myVCard.toString();

    const link = document.createElement("a");
    link.href =
      "data:text/plain;charset=utf-8," + encodeURIComponent(vCardText);
    link.download = profile_data.card_name + ".vcf";
    link.click();
  }
  if (typeof state?.html?.template_data !== "undefined") {
    return (
      <>
        <div className="bsn-ovrll">
          <div
            className="bsn-html-ovrll"
            dangerouslySetInnerHTML={{
              __html: state?.html?.template_data.replace(
                "{{qr_sticker}}",
                fetchQR()
              ),
            }}
          ></div>
        </div>
        <div className="but-overall">
          <button
            className="but-container"
            style={{
              backgroundColor:
                state?.html?.accent_secondary_color !== null &&
                typeof state?.html?.accent_secondary_color !== "undefined"
                  ? state?.html?.accent_secondary_color
                  : "#5412e0",
            }}
            onClick={() => {
              state?.html?.cardType === "Event"
                ? downloadICal()
                : downloadVCard();
            }}
          >
            <MdContactPhone
              color={
                state?.html?.accent_primary_color !== null &&
                typeof state?.html?.accent_primary_color !== "undefined"
                  ? state?.html?.accent_primary_color
                  : "#ffff"
              }
              style={{ width: "1.8rem", height: "1.8rem" }}
            />{" "}
            <p
              style={{
                color:
                  state?.html?.accent_primary_color !== null &&
                  typeof state?.html?.accent_primary_color !== "undefined"
                    ? state?.html?.accent_primary_color
                    : "#ffff",
              }}
            >
              SAVE
            </p>
          </button>
          <button
            className="but-container"
            style={{
              backgroundColor:
                state?.html?.accent_secondary_color !== null &&
                typeof state?.html?.accent_secondary_color !== "undefined"
                  ? state?.html?.accent_secondary_color
                  : "#5412e0",
            }}
            onClick={async () => {
              let payload = new FormData();
              payload.append("uid", state.html.uid);
              payload.append("email_instance_id", 13);
              payload.append("card_id", state.html.profile_id);

              await getEmailTemplate(payload).then((res) => {
                if (
                  res?.subject &&
                  res?.body &&
                  typeof res?.subject !== "undefined" &&
                  typeof res?.body !== "undefined"
                ) {
                  dispatch({
                    type: "UPDATE_EMAIL_TEMPLATE",
                    subject: res?.subject,
                    body: res?.body,
                  });
                } else {
                  dispatch({
                    type: "UPDATE_EMAIL_TEMPLATE",
                    subject: "preset subject here",
                    body: "<p>Enter your text here</p>",
                  });
                }
                dispatch({
                  type: "CHANGE_ONE_POPUP_STATE",
                  name: "isSharePanelOpen",
                  value: !state?.isSharePanelOpen,
                });
              });
            }}
          >
            <FaShareSquare
              color={
                state?.html?.accent_primary_color !== null &&
                typeof state?.html?.accent_primary_color !== "undefined"
                  ? state?.html?.accent_primary_color
                  : "#ffff"
              }
              style={{ width: "1.8rem", height: "1.8rem" }}
            />{" "}
            <p
              style={{
                color:
                  state?.html?.accent_primary_color !== null &&
                  typeof state?.html?.accent_primary_color !== "undefined"
                    ? state?.html?.accent_primary_color
                    : "#ffff",
              }}
            >
              SHARE
            </p>
          </button>
        </div>
        <StaffPopup
          dependencies={{
            State: state,
            Dispatch: dispatch,
          }}
        />
        <Footer
          logoEnabled={state?.html?.powered_by_logo_enabled}
          profileURL={state?.profile_url}
          accentColor={{
            accentPrimary: state?.html?.accent_primary_color,
            accentSecondary: state?.html?.accent_secondary_color,
          }}
        />
        {state?.isSharePanelOpen === true ? (
          <ShareFeature
            dependencies={{
              State: state,
              Dispatch: dispatch,
              booth_id: state?.html?.booth_id,
              isLeadSent: state?.isLeadSent,
              data: {
                email_instance_id: 13,
                source_id: state.html.profile_id,
              },
              uid: state?.html?.uid,
              email_template: state?.email_template,
            }}
          />
        ) : null}
        {state?.isLeadPanelOpen === true ? (
          <LeadFeature
            dependencies={{
              State: state,
              Dispatch: dispatch,
              selectOptions: state?.countryCodes,
              booth_id: state?.html?.booth_id,
              uid: state.html.uid,
            }}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <ErrorDynamic error={state?.html?.error} />
        <Footer logoEnabled={state?.html?.logoEnabled} />
      </>
    );
  }
};

export default BusinessCard;

export async function loader({ params }) {
  let profileId = params.userId;

  return await digitalCardTemplate({ profile_unique_url: profileId });
}
