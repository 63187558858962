import React from "react";
import "./css/error.scss";
import image from "../../assets/error.svg";

const ErrorDynamic = ({ error }) => {
  return (
    <>
      <div className="err-overall">
        <div className="err-cont-1">
          <div className="err-cont-1-in">
            <img className="err-img-main" src={image} />
            <span>{error}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorDynamic;
