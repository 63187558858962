import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();
  let profile = new URLSearchParams(window.location.search).get("p");

  useEffect(() => {
    if (typeof profile !== "undefined" && profile !== null) {
      navigate(`/${profile}?qr_scan=0`);
    }
  }, [profile]);

  return <div></div>;
};

export default Redirect;
