import React from "react";
import VCard from "vcard-creator";

export const DownloadVCard = (data) => {
  const myVCard = new VCard();

  const firstname = data[0]?.exhibitor_staff_first_name;
  const lastname = data[0]?.exhibitor_staff_last_name;
  const jobtitle = data[0]?.exhibitor_staff_job_title;
  const number =
    data[0]?.exhibitor_staff_mobile_no_country_code +
    " " +
    data[0]?.exhibitor_staff_mobile_no;
  const numberCode = data[0]?.exhibitor_staff_mobile_no_country_code;
  const address = data[0]?.exhibitor_staff_email_addresss;

  myVCard
    .addName(lastname, firstname)
    .addJobtitle(jobtitle)
    .addEmail(address)
    .addPhoneNumber(number, "WORK")
    .setFilename(firstname.vcf);

  console.log(myVCard.toString());

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(myVCard),
  );
  element.setAttribute("download", `${firstname}.vcf`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};
